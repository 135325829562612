import { Box, Button, Container, HStack, Input, Slider, SliderFilledTrack, SliderMark, SliderThumb, SliderTrack, Tab, TabList, TabPanel, TabPanels, Tabs, VStack } from "@chakra-ui/react";
import React, { useCallback, useDeferredValue, useMemo, useState } from "react";
import { LoanParameters } from "./LoanParametersEditor";
import { ShowLoans } from "./ShowLoans";
import { LoanPack, LoanPackEditor } from "./LoanPackEditor";
import { Gildi } from './loanProviders/Gildi'
import { Islandsbanki } from './loanProviders/Islandsbanki'
import { LoanProvider } from "./loanProviders/Types";
import { addInflation, calculateAnnuityLoan, calculateSimpleLoan } from "./LoanCalculator";
import { Loan } from "./API";

function providerLoanPack(provider: LoanProvider, purchacePrice: number | null, worth: number): LoanPack {
  return {
    worth,
    purchacePrice,
    providerTitle: provider.title,
    loanRatio: 0.5,
    baseLoan1: { years: 30, isAnnuity: true, isIndexLinked: false, isFixedRate: false, interest: 0.058 },
    baseLoan2: { years: 30, isAnnuity: false, isIndexLinked: true, isFixedRate: false, interest: 0.016 },
    extraLoan: { years: 30, isAnnuity: true, isIndexLinked: true, isFixedRate: false, interest: 0.028 },
  }
}

const loanF = (loan: LoanParameters, loanRatio: number, isBaseLoan: boolean) => ({
  isBaseLoan,
  isIndexBased: loan.isIndexLinked,
  isVariableInterest: !loan.isFixedRate,
  isAnnuitet: loan.isAnnuity,
  rate: loanRatio,
  years: loan.years,
  interests: loan.interest
});

const packF = (p: LoanPack) => [
  loanF(p.baseLoan1, 1 - p.loanRatio, true),
  loanF(p.baseLoan2, p.loanRatio, true),
  loanF(p.extraLoan, 1, false)
]
const amounts = (provider: LoanProvider, p: LoanPack) => provider.loanAmounts(p.purchacePrice, p.worth)

export function calculateLoan(providers: LoanProvider[], loanPack: LoanPack, interestAdjustment: number, inflation: number) {
  const provider = providers.filter(p => p.title === loanPack.providerTitle)[0]
  if (!provider) throw Error('Unknown loan provider: ' + loanPack.providerTitle)
  const loans = packF(loanPack)
  const baseAndExtra = amounts(provider, loanPack)
  return loans.map(ln => {
    const payments = ln.isAnnuitet
      ? calculateAnnuityLoan(
        (ln.isBaseLoan ? baseAndExtra.baseLoan : baseAndExtra.extraLoan) * ln.rate,
        ln.years,
        ln.interests + (ln.isVariableInterest && !ln.isIndexBased ? interestAdjustment : 0),
        { extraDays: 0, paymentsPerYear: 12 }
      )
      : calculateSimpleLoan(
        (ln.isBaseLoan ? baseAndExtra.baseLoan : baseAndExtra.extraLoan) * ln.rate,
        ln.years,
        ln.interests + (ln.isVariableInterest && !ln.isIndexBased ? interestAdjustment : 0),
        { extraDays: 0, paymentsPerYear: 12 }
      )

    const inflatedPayments = ln.isIndexBased
      ? addInflation(inflation, { extraDays: 0, paymentsPerYear: 12 }, payments)
      : Array.from(payments).map(p => ({ ...p, inflated: p }))

    return {
      title: loanTitle(ln),
      amount: (ln.isBaseLoan ? baseAndExtra.baseLoan : baseAndExtra.extraLoan) * ln.rate,
      payments: Array.from(inflatedPayments)
    }
  })
}


export function useLocalStorageState<S>(key: string, initialState: S | (() => S)): [S, React.Dispatch<React.SetStateAction<S>>] {
  const [currentState, setCurrentState] = useState<S>(() => {
    if (typeof window === 'undefined') {
      return initialState
    }

    try {
      const item = window.localStorage.getItem(key)
      return item ? JSON.parse(item) : initialState
    } catch (error) {
      console.error(error)
      return initialState
    }
  })

  const setter = useCallback((arg: React.SetStateAction<S>) => {
    const value = arg instanceof Function ? arg(currentState) : arg
    setCurrentState(value)
    if (typeof window !== 'undefined') {
      try {
        window.localStorage.setItem(key, JSON.stringify(value))
      } catch (error) {
        console.error(error)
      }
    }
  }, [key, setCurrentState, currentState])

  return [currentState, setter]
}


export function LoansForm() {
  const [scenarios, setScenarios] = useLocalStorageState<string[]>('scenarios', [])
  const [newName, setNewName] = useState('')

  const createScenario = useCallback((name: string) => {
    setScenarios(xs => [...xs, name])
    setNewName('')
  }, [setScenarios, setNewName])

  const deleteScenario = useCallback((name: string) => {
    if (typeof window !== 'undefined') {
      window.localStorage.removeItem('loans-' + name)
    }

    setScenarios(xs => xs.filter(x => x !== name))
  }, [setScenarios])

  const renameScenario = useCallback((name: string, newName: string) => {
    if (typeof window !== 'undefined') {
      const was = window.localStorage.getItem('loans-' + name)
      if (!was) {
        return
      }
      window.localStorage.setItem('loans-' + newName, was)
      window.localStorage.removeItem('loans-' + name)
    }

    setScenarios(xs => xs.map(x => x === name ? newName : x))
    setNewName('')
  }, [setScenarios])

  const cloneScenario = useCallback((name: string, newName: string) => {
    if (typeof window !== 'undefined') {
      const was = window.localStorage.getItem('loans-' + name)
      if (!was) {
        return
      }
      window.localStorage.setItem('loans-' + newName, was)
      window.localStorage.removeItem('loans-' + name)
    }

    setScenarios(xs => [...xs, newName])
    setNewName('')
  }, [setScenarios])

  return <Tabs isLazy>
    <TabList>
      {scenarios.map(s => <Tab key={s}>{s}</Tab>)}
      <HStack>
        <Input value={newName} onChange={ev => setNewName(ev.target.value)} />
        <Button disabled={!newName.trim()} onClick={() => createScenario(newName.trim())}>New</Button>
      </HStack>
    </TabList>
    <TabPanels>
      {scenarios.map(s => <TabPanel key={s}>
        <HStack>
          <Button disabled={!newName.trim()} onClick={() => cloneScenario(s, newName.trim())}>Clone</Button>
          <Button disabled={!newName.trim()} onClick={() => renameScenario(s, newName.trim())}>Rename</Button>
          <Button onClick={() => deleteScenario(s)}>Delete</Button>
        </HStack>
        <LoansScenario name={s} />
      </TabPanel>)}

    </TabPanels>
  </Tabs>
  // <LoansScenario name="default" />
}

function *ntimes<T>(n: number, value: T) {
  for (let i = 0; i < n; ++i) {
    yield value
  }
}


function LoansScenario({ name }: { name: string }) {
  const [inflationRoot, setInflationRoot] = useState(0.0607 ** (1 / 2))
  const inflation = inflationRoot ** 2
  const [interestAdjustment, setInterestAdjustment] = useState(0)
  const [loanPacks, setLoanPacks] = useLocalStorageState<LoanPack[]>('loans-' + name, [])

  const providers = [Gildi, Islandsbanki]

  const deferredLoanPacks = useDeferredValue(loanPacks)
  const deferredInflation = useDeferredValue(inflation)
  const deferredInterestAdjustment = useDeferredValue(interestAdjustment)

  const graphData = useMemo(() => 
    deferredLoanPacks.map(loanPack => ({
      title: loanPack.providerTitle,
      worth: Array.from(addInflation(
        deferredInflation,
        { extraDays: 0, paymentsPerYear: 12 },
        Array.from(ntimes(480, loanPack.worth))
          .map((w,i) => ({ principal: w, paymentNumber: i, principalPayment: 0, interestPayment: 0}))
        )).map(i => i.inflated.principal),
      price: loanPack.purchacePrice === null ? null : Array.from(addInflation(
        deferredInflation,
        { extraDays: 0, paymentsPerYear: 12 },
        Array.from(ntimes(480, loanPack.purchacePrice))
          .map((w,i) => ({ principal: w, paymentNumber: i, principalPayment: 0, interestPayment: 0}))
        )).map(i => i.inflated.principal),
      loans: calculateLoan(providers, loanPack, deferredInterestAdjustment, deferredInflation)
    })),
    [providers, deferredLoanPacks, deferredInterestAdjustment, deferredInflation]
    )

  return <Box m={2}>
    <HStack>
      <ShowLoans loanss={graphData} />

      <VStack>
        <HStack>
          {providers.map(p => <Button key={p.title} onClick={() => setLoanPacks(ps => [...ps, providerLoanPack(p, 60_000_000, 50_000_000)])}>Add {p.title} loan</Button>)}
        </HStack>

        {loanPacks.map((loanPack, i) =>
          <LoanPackEditor
            key={i}
            providers={providers}
            loanPack={loanPack}
            onChange={p => setLoanPacks(ps => [...ps.slice(0, i), p, ...ps.slice(i + 1)])}
            onRemove={() => setLoanPacks(ps => [...ps.slice(0, i), ...ps.slice(i + 1)])}
          />
        )}

        <Container>
          <HStack>
            <VStack>
              <HStack>
                <h3>Inflation</h3>
                <Box w="4rem">
                  {Math.round(inflation * 10000) / 100}%
                </Box>
              </HStack>
              <Slider aria-label='slider-inflation' colorScheme='pink'
                min={0}
                max={0.5}
                step={0.0001}
                defaultValue={inflationRoot} onChange={setInflationRoot}>
                <SliderFilledTrack />
                <SliderTrack>
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </VStack>

            <VStack>
              <HStack>
                <h3>Interest adjustment</h3>
                <Box w="4rem">
                  {Math.round(interestAdjustment * 10000) / 100}%
                </Box>
              </HStack>
              <Slider aria-label='slider-interestadjustment' colorScheme='pink'
                min={-0.1}
                max={0.1}
                step={0.0001}
                defaultValue={interestAdjustment} onChange={setInterestAdjustment}>
                <SliderMark value={-0.05} mt='1' ml='-2.5' fontSize='xs'>-5%</SliderMark>
                <SliderMark value={-0.02} mt='1' ml='-2.5' fontSize='xs'>-2%</SliderMark>
                <SliderMark value={0} mt='1' ml='-2.5' fontSize='xs'>0%</SliderMark>
                <SliderMark value={0.02} mt='1' ml='-2.5' fontSize='xs'>2%</SliderMark>
                <SliderMark value={0.05} mt='1' ml='-2.5' fontSize='xs'>5%</SliderMark>
                <SliderFilledTrack />
                <SliderTrack>
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </VStack>
            <Button size="xs" onClick={_ => setInterestAdjustment(0)}>Reset</Button>
          </HStack>
        </Container>
      </VStack>
    </HStack>

  </Box>
}



function loanTitle(loan: Loan) {
  const baseOrNot = loan.isBaseLoan ? 'Base loan' : 'Extra loan'
  const annuity = loan.isAnnuitet ? 'annuity' : 'simple'
  const indexed = loan.isIndexBased ? 'index based' : 'non-index based'
  const variability = loan.isVariableInterest ? 'variable rate' : 'fixed rate'
  return `${baseOrNot} ${annuity} ${indexed} ${variability}`
}


