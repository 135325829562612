export type PaymentDetails = { paymentNumber: number } & PaymentAmounts
export type PaymentAmounts = {
    principal: number,
    principalPayment: number
    interestPayment: number
}

export function splitLoan(amount: number, factor: number) {
    return [amount * factor, amount * (1 - factor)]
}

export function annuityIndexLoanPayment(principal: number, rate: number, inflation: number, years: number, ppy = 12) {
    const rper = rate / ppy
    const gper = inflation / ppy
    const r = (1 + rper) / (1 + gper) - 1
    const npay = years * ppy

    return (r * principal) / (1 - (1 + r) ** -npay)
}

export function futureInterestValue(inflation: number, periods: number, principal: number, ppy = 12) {
    const r = inflation / ppy
    return principal * (1 + r) ** periods
}

export function futureInflationValue(inflation: number, periods: number, principal: number, ppy = 12) {
    const wholeYears = Math.floor(periods / ppy)
    const remainingPeriods = periods - wholeYears * ppy
    const rper = (1 + inflation) ** (1 / ppy) - 1
    return principal * ((1 + inflation) ** wholeYears) * ((1 + rper) ** remainingPeriods)
}

export function* calculateAnnuityLoan(
    principal: number,
    years: number,
    interestRate: number,
    loan: { extraDays: number, paymentsPerYear: number }
): Generator<PaymentDetails>  {
    let payment = calculateAnnuityPayment(
        principal,
        principal,
        1,
        interestRate,
        loan.paymentsPerYear,
        years,
        30 + loan.extraDays
    )

    yield payment;

    for (let i = 2; i <= years * loan.paymentsPerYear; ++i) {
        payment = calculateAnnuityPayment(principal, payment.principal, i, interestRate, loan.paymentsPerYear, years, 30)
        yield payment;
    }
}

export function* calculateSimpleLoan(
    principal: number,
    years: number,
    interestRate: number,
    loan: { extraDays: number, paymentsPerYear: number }
): Generator<PaymentDetails> {
    const numberOfPayments = years * loan.paymentsPerYear
    const principalPayment = principal / numberOfPayments
    const rper = interestRate / loan.paymentsPerYear
    for (let i = 1; i <= numberOfPayments; ++i) {
        yield {
            paymentNumber: i,
            principal: principal - principalPayment*i,
            principalPayment,
            interestPayment: (principal - principalPayment*(i-1)) * rper
        };
    }
}


export function *addInflation(inflation: number, loan: { extraDays: number, paymentsPerYear: number }, payments: Iterable<PaymentDetails>)
    : Generator<PaymentDetails & { inflated: PaymentAmounts }> {
    for (const p of payments) {
        yield { ...p, inflated: calculateInflation(inflation, loan, p) }
    }
}

export function calculateAnnuityPayment(
    baseValue: number,
    presentValue: number,
    paymentNumber: number,
    interestRate: number,
    paymentsPerYear: number,
    numberOfYears: number,
    numberOfInterestDays: number
): PaymentDetails {    
    const rper = interestRate / paymentsPerYear
    const nominalPayment = annuityIndexLoanPayment(baseValue, interestRate, 0, numberOfYears, paymentsPerYear)
    const interest = presentValue * rper
    const interestCorrection = interest * numberOfInterestDays / 30
    const downpayment = nominalPayment - interest
    
    return {
        principal: Math.round(presentValue - downpayment),
        principalPayment: Math.round(downpayment),
        interestPayment: Math.round(interestCorrection),
        paymentNumber
    }
}


export function calculateInflation(inflation: number, loan: { extraDays: number, paymentsPerYear: number }, payment: PaymentDetails) {
    const fv = (amount: number) => futureInflationValue(inflation, payment.paymentNumber + loan.extraDays / 360 * loan.paymentsPerYear, amount, loan.paymentsPerYear)
    return {
        principalPayment: Math.round(fv(payment.principalPayment)),
        interestPayment: Math.round(fv(payment.interestPayment)),
        principal: Math.round(fv(payment.principal))
    }
}
