
export function* nth<T>(n: number, es: T[]) {
  let emitted:number|null = null
  for (let i = 0; i < es.length; i += n) {
    yield es[i];
    emitted = i
  }

  if (emitted !== es.length-1) {
    yield es[es.length - 1];
  }
}
