import { useCallback, useEffect, useState } from "react";


export function useField<R, K extends keyof R>(record: R, field: K, onChange: (record: R) => void): [R[K], (value: R[K]) => void] {
  const [value, setValue] = useState<R[K]>(record[field])
  
  useEffect(() => {
    const n = record[field]
    setValue(n)
  }, [record[field]])

  const setter = useCallback((val: R[K]) => {
    const updated = { ...record, [field]: val }
    onChange(updated)
  }, [record, field, onChange])

  return [value, setter]
}
