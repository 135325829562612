import { LoanProvider } from './Types'

export const Gildi: LoanProvider = {
    title: 'Life Teal',
    
    loanAmounts: (purchaseValue: number|null, worth: number) => {
        const baseLoan = Math.round(worth * .65)
        return {
            baseLoan,
            extraLoan: Math.max(0, Math.round((purchaseValue ?? worth) * .75 - baseLoan - 12500))
        }
    },

    offersMixedExtraLoan: false,

    interests: [
        {
            fixedYears: null,
            indexLinked: false,
            baseLoan: true,
            rate: 0.0335
        },
        {
            fixedYears: null,
            indexLinked: false,
            baseLoan: false,
            rate: 0.0385
        },
        {
            fixedYears: null,
            indexLinked: true,
            baseLoan: true,
            rate: 0.018
        },
        {
            fixedYears: null,
            indexLinked: true,
            baseLoan: false,
            rate: 0.0255
        },
        {
            fixedYears: 5,
            indexLinked: true,
            baseLoan: true,
            rate: 0.031
        },
        {
            fixedYears: 5,
            indexLinked: true,
            baseLoan: false,
            rate: 0.0385
        }
    ]
}
