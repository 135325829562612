import React, { Fragment, useCallback } from "react";
import { EquityGraph, LoanGraph } from "./LoanGraph";
import { LoanDetails, PaymentWithInflation, padPayments } from "./FetchData";
import { Box, Grid, GridItem, HStack } from "@chakra-ui/react";


const padToLongest = (longest: number, payments: PaymentWithInflation[]) =>
  padPayments(longest, payments)

function *range(n: number) {
  for (let i = 0; i < n; ++i) yield i
}

export function ShowLoans({ loanss }: { loanss: { title: string, loans: LoanDetails[], worth: number[], price: number[] | null }[]; }) {
  const loans = loanss.flatMap(x => x.loans)
  const longestLoan = Math.max(...loans.map(d => d.payments.length));


  return <Grid templateColumns="1fr 3fr">
    <GridItem>
      <h3>Principal (first 24)</h3>
      <LoanGraph
        maxPayments={24}
        width={200}
        height={400}
        scale={6}
        scaleMark="M"
        loans={loans.map((loan, i) => ({
          id: i + '',
          payments: padToLongest(24, loan.payments.slice(0, 24)).map(pm => ({
            paymentNumber: pm.paymentNumber, amount: pm.inflated.principal
          }))
        }))} />
    </GridItem>

    <GridItem>
      <h3>Principal</h3>
      <LoanGraph
        maxPayments={480}
        width={700}
        height={400}
        scale={6}
        scaleMark="M"
        loans={loans.map((loan, i) => ({
          id: i + '',
          payments: padToLongest(longestLoan, loan.payments).map(pm => ({
            paymentNumber: pm.paymentNumber, amount: pm.inflated.principal
          }))
        }))} />
    </GridItem>


    <GridItem>
      <h3>Payments (first 24)</h3>
      <LoanGraph
        maxPayments={24}
        width={200}
        height={400}
        scale={3}
        scaleMark="K"
        loans={loans.map((loan, i) => ({
          id: i + '',
          payments: padToLongest(24, loan.payments.slice(0, 24)).map(pm => ({
            paymentNumber: pm.paymentNumber,
            amount: pm.inflated.principalPayment + pm.inflated.interestPayment
          }))
        }))} />
    </GridItem>


    <GridItem>
      <h3>Payments</h3>
      <LoanGraph
        maxPayments={480}
        width={700}
        height={400}
        scale={3}
        scaleMark="K"
        loans={loans.map((loan, i) => ({
          id: i + '',
          payments: padToLongest(longestLoan, loan.payments).map(pm => ({
            paymentNumber: pm.paymentNumber, amount: pm.inflated.principalPayment + pm.inflated.interestPayment
          }))
        }))} />
    </GridItem>

    {loanss.map((loanPack, i) => {
      const data = Array.from(range(480)).map(pm => ({
        paymentNumber: pm,
        worth: loanPack.worth[pm],
        price: loanPack.price === null ? null : loanPack.price[pm],
        principal: loanPack.loans.map(ln => (ln.payments[pm]||{inflated:{principal:0}}).inflated.principal).reduce((a,b) => a+b)
      }))
      
      return <Fragment key={i}>
        <GridItem>
          <h3>{loanPack.title} equity (first 24)</h3>
          <EquityGraph
            maxPayments={24}
            width={200}
            height={400}
            data={data.slice(0, 24)}
          />
        </GridItem>

        <GridItem>
          <h3>{loanPack.title} equity</h3>
          <EquityGraph
            maxPayments={480}
            width={700}
            height={400}
            data={data}
          />
        </GridItem>
      </Fragment>
    })}

    <GridItem colSpan={2}>
      First payment: {Math.round(loans
        .map(ln => ln.payments[0].inflated.principalPayment + ln.payments[0].inflated.interestPayment)
        .reduce((a, b) => a + b, 0)
        / 1000)
      } K
      <HStack>
        ({loans.map((ln, i) =>
          <Box key={i}>
            {Math.round((ln.payments[0].inflated.principalPayment + ln.payments[0].inflated.interestPayment) / 1000)} K
          </Box>)})
      </HStack>


      First year average: {Math.round(loans
        .flatMap(ln => ln.payments.slice(0, 12).map(p => p.inflated.principalPayment + p.inflated.interestPayment))
        .reduce((a, b) => a + b, 0)
        / 12 / 1000)
      } K
      <HStack>
        ({loans.flatMap((ln, i) =>
          <Box key={i}>
            {Math.round(
              ln.payments.slice(0, 12)
                .map(p => p.inflated.principalPayment + p.inflated.interestPayment)
                .reduce((a, b) => (a + b)) / 12 / 1000)} K
          </Box>)})
      </HStack>


      Initial principal: {Math.round(loans
        .map(ln => ln.payments[0].inflated.principal)
        .reduce((a, b) => a + b, 0)
        / 1_000_000)
      } M

      <HStack>
        ({loans.map((ln, i) =>
          <Box key={i}>
            {Math.round(ln.payments[0].inflated.principal / 1_000_000)} M
          </Box>)})
      </HStack>
    </GridItem>

  </Grid>
}