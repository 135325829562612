import { LoanProvider } from './Types'

export const Islandsbanki: LoanProvider = {
    title: 'Red national',

    loanAmounts: (purchaseValue: number|null, worth: number) => {
        const baseLoan = Math.round(worth * .70)
        return {
            baseLoan,
            extraLoan: Math.max(0, Math.min(75_000_000-baseLoan, Math.round((purchaseValue ?? worth) * .8 - baseLoan)))
        }
    },

    offersMixedExtraLoan: true,

    interests: [
        {
            fixedYears: null,
            indexLinked: false,
            baseLoan: true,
            rate: 0.0465
        },
        {
            fixedYears: null,
            indexLinked: false,
            baseLoan: false,
            rate: 0.0575
        },
        {
            fixedYears: 3,
            indexLinked: false,
            baseLoan: true,
            rate: 0.0615
        },
        {
            fixedYears: 3,
            indexLinked: false,
            baseLoan: false,
            rate: 0.0725
        },
        {
            fixedYears: 5,
            indexLinked: false,
            baseLoan: true,
            rate: 0.0655
        },
        {
            fixedYears: 5,
            indexLinked: false,
            baseLoan: false,
            rate: 0.0775
        },
        {
            fixedYears: 5,
            indexLinked: true,
            baseLoan: true,
            rate: 0.015
        },
        {
            fixedYears: 5,
            indexLinked: true,
            baseLoan: false,
            rate: 0.026
        },
        {
            fixedYears: null,
            indexLinked: true,
            baseLoan: true,
            rate: 0.027
        },
        {
            fixedYears: null,
            indexLinked: true,
            baseLoan: false,
            rate: 0.038
        }

    ]
}
