import { PaymentAmounts, PaymentDetails } from "./LoanCalculator"

function asNumber(x: string | number) {
  if (typeof x === 'string') {
    return parseFloat(x) // (x.replace('.', '').replace(',', '.'))
  } else {
    return x
  }
}

export type PaymentWithInflation =
  PaymentDetails & { inflated: PaymentAmounts }

export type LoanDetails = {
  title: string,
  payments: PaymentWithInflation[]
}


export function padPayments(longestLoan: number, payments: PaymentWithInflation[]) {
  const missing = longestLoan - payments.length
  const padding: PaymentWithInflation[] = [...Array(missing)]
    .map((_, i) => ({
      paymentNumber: payments.length + i + 1,
      principal: 0,
      principalPayment: 0,
      interestPayment: 0,
      inflated: {
        principal: 0,
        principalPayment: 0,
        interestPayment: 0,
      }
    }))

  const r = payments.concat(padding)

  return r
}



