import { Box, Button, Center, GridItem, HStack, SimpleGrid, Slider, SliderFilledTrack, SliderMark, SliderThumb, SliderTrack, Switch, VStack } from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { LoanParameters, LoanParametersEditor } from "./LoanParametersEditor";
import { LoanProvider } from "./loanProviders/Types";
import { useField } from "./useField";


export type LoanPack = {
  purchacePrice: number|null,
  worth: number,
  loanRatio: number,
  baseLoan1: LoanParameters,
  baseLoan2: LoanParameters,
  extraLoan: LoanParameters,
  providerTitle: string
}

function formatMillions(n:number) {
  return `${Math.round(n / 10000) / 100}M`
}

export function LoanPackEditor({ providers, loanPack, onChange, onRemove }: { providers: LoanProvider[], loanPack: LoanPack; onChange: (value: LoanPack) => void; onRemove?: () => void }) {
  const [baseLoan1, setBaseLoan1] = useField(loanPack, 'baseLoan1', onChange)
  const [baseLoan2, setBaseLoan2] = useField(loanPack, 'baseLoan2', onChange)
  const [extraLoan, setExtraLoan] = useField(loanPack, 'extraLoan', onChange)
  const [loanRatio, setLoanRatio] = useField(loanPack, 'loanRatio', onChange)
  const [purchasePrice, setPurchasePrice] = useField(loanPack, 'purchacePrice', onChange)
  const [worth, setWorth] = useField(loanPack, 'worth', onChange)

  const provider = useMemo(() => providers.filter(p => p.title === loanPack.providerTitle)[0], [JSON.stringify(providers), loanPack])
  if (!provider) throw Error('Unknown loan provider: ' + loanPack.providerTitle)
  const amounts = useMemo(() => provider.loanAmounts(loanPack.purchacePrice, loanPack.worth), [loanPack.purchacePrice, loanPack.worth])

  const percentage = (amounts.baseLoan + amounts.extraLoan)  / loanPack.worth

  return <SimpleGrid columns={3} spacing="12px" bg="whitesmoke" padding="20px">
    <GridItem colSpan={3}>
      {provider.title}
      <Center>{formatMillions(amounts.baseLoan + amounts.extraLoan)} ({Math.round(percentage * 100)}% of {formatMillions(loanPack.worth)})</Center>
      {onRemove ? <Button onClick={onRemove}>Remove</Button> : null}
      <VStack>
        <HStack>
          <h3>Purchase</h3>
          <Switch checked={purchasePrice !== null} onChange={x => setPurchasePrice(x.target.checked ? 50_000_000 : null)} />
          {purchasePrice !== null ? <Box w="2rem">{Math.round(purchasePrice/1_000_000)} M</Box> : null}
        </HStack>
        {purchasePrice !== null ? <Slider aria-label='Years' colorScheme='pink'
          min={10_000_000}
          max={200_000_000}
          step={1_000_000}
          value={purchasePrice ?? 0}
          onChange={setPurchasePrice}>
          <SliderFilledTrack />
          <SliderTrack>
          </SliderTrack>
          <SliderThumb />
        </Slider> : null}
      </VStack>

      <VStack>
        <HStack>
          <h3>Worth</h3>
          <Box w="2rem">{Math.round(worth/1_000_000)} M</Box>
        </HStack>
        <Slider aria-label='Years' colorScheme='pink'
          min={10_000_000}
          max={200_000_000}
          step={1_000_000}
          value={worth}
          onChange={setWorth}>
          <SliderFilledTrack />
          <SliderTrack>
          </SliderTrack>
          <SliderThumb />
        </Slider>
      </VStack>

    </GridItem>
    <GridItem bg="skyblue" padding="20px" data-loan="base1">
      <Center>
        <Center>{formatMillions(amounts.baseLoan * (1-loanRatio))}</Center>
      </Center>
      <LoanParametersEditor isBaseLoan={true} value={baseLoan1} onChange={setBaseLoan1} provider={provider} />
    </GridItem>
    <GridItem bg="skyblue" padding="20px" data-loan="base2">
      <Center>{formatMillions(amounts.baseLoan * loanRatio)}</Center>
      <LoanParametersEditor isBaseLoan={true} value={baseLoan2} onChange={setBaseLoan2} provider={provider} />
    </GridItem>
    <GridItem padding="20px" bg="tomato" data-loan="extra1">
      <Center>{formatMillions(amounts.extraLoan)}</Center>
      <LoanParametersEditor isBaseLoan={false} value={extraLoan} onChange={setExtraLoan} provider={provider} />
    </GridItem>

    <GridItem colSpan={2}>
      <VStack>

        <Slider aria-label='slider-ratio' colorScheme='pink'
          min={0}
          max={1}
          step={0.01}
          defaultValue={loanRatio} onChange={setLoanRatio}>
          <SliderFilledTrack />
          <SliderTrack>
          </SliderTrack>
          <SliderThumb />
        </Slider>
        {/* <HStack>
        <h3>Loan ratio</h3>
        <Box w="4rem">{Math.round(indexedRatio * 10000) / 100}%</Box>
      </HStack> */}

      </VStack>
    </GridItem>
  </SimpleGrid>
}
