import { Box, Button, Container, FormControl, FormLabel, HStack, Slider, SliderFilledTrack, SliderMark, SliderThumb, SliderTrack, Switch, VStack } from "@chakra-ui/react";
import React, { useId } from "react";
import { LoanProvider } from "./loanProviders/Types";
import { useField } from "./useField";


export type LoanParameters = {
  years: number,
  interest: number,
  isAnnuity: boolean,
  isIndexLinked: boolean,
  isFixedRate: boolean
}

export function LoanParametersEditor({ isBaseLoan, value, onChange, provider }: { isBaseLoan: boolean, provider: LoanProvider, value: LoanParameters; onChange: (value: LoanParameters) => void; }) {
  const [years, setYears] = useField(value, 'years', onChange);
  const [interest, setInterest] = useField(value, 'interest', onChange);
  const [isAnnuity, setIsAnnuity] = useField(value, 'isAnnuity', onChange);
  const [isFixedRate, setIsFixedRate] = useField(value, 'isFixedRate', onChange);
  const [isIndexLinked, setIsIndexLinked] = useField(value, 'isIndexLinked', onChange);

  const id = useId();

  const providerRate = provider.interests.filter(i => 
    i.baseLoan === isBaseLoan
    && !!i.fixedYears === isFixedRate
    && i.indexLinked === isIndexLinked
    )[0]?.rate

  return <VStack>
    <Container>
      <VStack>
        <HStack>
          <h3>Years</h3>
          <Box w="2rem">{value.years}</Box>
        </HStack>
        <Slider aria-label='Years' colorScheme='pink'
          min={1}
          max={40}
          step={1}
          value={years}
          onChange={setYears}>
            <SliderMark value={10} mt='1' ml='-2.5' fontSize='xs'>10</SliderMark>
            <SliderMark value={20} mt='1' ml='-2.5' fontSize='xs'>20</SliderMark>
            <SliderMark value={30} mt='1' ml='-2.5' fontSize='xs'>30</SliderMark>
          <SliderFilledTrack />
          <SliderTrack>
          </SliderTrack>
          <SliderThumb />
        </Slider>
      </VStack>
    </Container>

    <Container>
      <VStack>
        <HStack>
          <h3>Interest</h3>
          <Box w="4rem">
            {Math.round(value.interest * 100_00) / 100}%
          </Box>
        </HStack>
        <Slider aria-label='Interest rate' colorScheme='pink'
          min={0}
          max={0.2}
          step={0.00001}
          value={interest}
          onChange={setInterest}>
            {providerRate
              ? <SliderMark value={providerRate} mt='1' ml='-2.5' fontSize='xs'>{Math.round(providerRate*100_00)/100}%</SliderMark>
              : null
            }
          <SliderFilledTrack />
          <SliderTrack>
          </SliderTrack>
          <SliderThumb />
        </Slider>

        <Button size="xs" onClick={_ => setInterest(providerRate)}>Reset</Button>
      </VStack>
    </Container>


    <FormControl display='flex' alignItems='center'>
      <FormLabel htmlFor={`${id}-annuity`} mb='0'>
        Annuity payments
      </FormLabel>
      <Switch id={`${id}-annuity`} isChecked={isAnnuity} onChange={ev => setIsAnnuity(ev.target.checked)} />
    </FormControl>

    <FormControl display='flex' alignItems='center'>
      <FormLabel htmlFor={`${id}-index`} mb='0'>
        Index linked
      </FormLabel>
      <Switch id={`${id}-index`} isChecked={isIndexLinked} onChange={ev => setIsIndexLinked(ev.target.checked)} />
    </FormControl>

    <FormControl display='flex' alignItems='center'>
      <FormLabel htmlFor={`${id}-fixed`} mb='0'>
        Fixed interests
      </FormLabel>
      <Switch id={`${id}-fixed`} isChecked={isFixedRate} onChange={ev => setIsFixedRate(ev.target.checked)} />
    </FormControl>
  </VStack>;
}
