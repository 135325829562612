import React from "react";
import { Line } from '@nivo/line';
import * as API from './API';
import { nth } from "./helpers";


export type PaymentDetails = {
  paymentNumber: number,
  amount: number
}

export function LoanGraph({ maxPayments, loans, scale, scaleMark, width, height }: { maxPayments: number, width: number, height: number, loans: { id: string; payments: PaymentDetails[]; }[], scale: number, scaleMark: string }) {
  return <Line
    height={height}
    width={width}
    yScale={{
      type: 'linear',
      stacked: true
    }}
    xScale={{
      type: 'linear',
      max: maxPayments
    }}
    margin={{
      top: 20,
      left: 50,
      right: 20,
      bottom: 50
    }}
    axisLeft={{
      format: x => (x / (10 ** scale)) + ' ' + scaleMark
    }}
    axisBottom={{
      tickRotation: -45
    }}

    data={loans.map(loan => ({
      id: loan.id,
      data: Array.from(nth(loan.payments.length > 48 ? 12 : 1, loan.payments)).map(gd => ({ x: gd.paymentNumber, y: gd.amount }))
    }))}
    
    />;
}

type EquityData = { paymentNumber: number, worth: number, price: number|null, principal: number }[]

export function EquityGraph({ maxPayments, width, height, data }: { maxPayments: number, width: number, height: number, data: EquityData }) {
  return <Line
    height={height}
    width={width}
    yScale={{
      type: 'linear',
      stacked: false
    }}
    xScale={{
      type: 'linear',
      max: maxPayments
    }}
    margin={{
      top: 20,
      left: 50,
      right: 20,
      bottom: 50
    }}
    axisLeft={{
      format: x => (x / (10 ** 6)) + ' ' + 'M'
    }}
    axisBottom={{
      tickRotation: -45
    }}

    colors={d => d.id === 'principal' ? 'tomato' : d.id === 'worth' ? 'forestgreen' : 'royalblue'}
    
    data={[{
      id: 'worth',
      data: Array.from(nth(maxPayments > 48 ? 12 : 1, data.map(x => ({x: x.paymentNumber, y: x.worth }))))
    },  {
      id: 'price',
      data: Array.from(nth(maxPayments > 48 ? 12 : 1, data.map(x => ({x: x.paymentNumber, y: x.price??0 }))))
    }, {
      id: 'principal',
      data: Array.from(nth(maxPayments > 48 ? 12 : 1, data.map(x => ({x: x.paymentNumber, y: x.principal }))))
    }]}
    
    />;
}

